import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import aboutStyles from "./about.module.css"
import Solution from "../images/solution-brand.png"


export default function About() {
    return (
        <Container fluid="xl">
            <Row>
                <Col md={5} className={aboutStyles.about_left}>
                <h2><span className={aboutStyles.primary}>Jamstack</span> เทคโนโลยีการทำเว็บไซต์สมัยใหม่</h2>
                <p><b>เร็วขึ้นและปลอดภัยกว่า</b> เป็นหัวใจสำคัญต่อการเข้าถึงผู้ใช้งานเว็บไซต์ สิ่งนี้เป็นแบบเดียวกับ Google ที่ให้ความสำคัญและพัฒนาระบบเว็บไซต์ใหม่นี้ขึ้นมา เราเพียงพัฒนาตามเทรดโลกที่เปลี่ยนไปออกมาเป็น Solution
                เพื่อตอบสนองต่อการใช้งานในรูปแบบใหม่ที่จะเปลี่ยนไป เราคัดสรรรูปแบบนี้มาอย่างดีเพื่อให้คุณได้รับบริการทำเว็บไซต์ที่ดีที่สุด</p>
                <Button rel="noreferrer" href="https://lin.ee/O1vC4Bk" target="_blank" variant="primary" size="lg">สอบถามเพิ่มเติม</Button>{' '}
                </Col>
                <Col md={7} className={aboutStyles.about_right}><img className={aboutStyles.brand} src={Solution} width="100%" alt="Jamstack" /></Col>
            </Row>
        </Container>
    );
}