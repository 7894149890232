import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import brandStyles from "./brand.module.css"
import Brandaifirst from "../images/brand-aifirst.png"
import Brandphoenix from "../images/brand-phoenix.png"
import BrandSentoshi from "../images/brand-sentoshi.png"
import BrandSongthai from "../images/brand-songthai.png"
import BrandWorldwireradio from "../images/brand-worldwireradio.png"


export default function Brand() {
    return (
        <Container fluid="xl" className={brandStyles.brand_container}>
            <Row>
                <Col sm><center><img className={brandStyles.brand} src={Brandaifirst} alt="onigitop" /></center></Col>
                <Col><center><img className={brandStyles.brand} src={Brandphoenix} alt="onigitop" /></center></Col>
                <Col><center><img className={brandStyles.brand} src={BrandSentoshi} alt="onigitop" /></center></Col>
                <Col><center><img className={brandStyles.brand} src={BrandSongthai} alt="onigitop" /></center></Col>
                <Col><center><img className={brandStyles.brand} src={BrandWorldwireradio} alt="onigitop" /></center></Col>
            </Row>
        </Container>
    );
}