import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "../components/container"
import Hero from "../components/hero"
import Brand from "../components/brand"
import Portfolio from "../components/portfolio"
import Price from "../components/price"
import Why from "../components/why"
import About from "../components/about"
import Callaction from "../components/callaction"
import SEO from "../components/seo"

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#hero"]');
  require('smooth-scroll')('a[href*="#price"]');
  require('smooth-scroll')('a[href*="#portfolio"]');
  require('smooth-scroll')('a[href*="#about"]');
  require('smooth-scroll')('a[href*="#contact"]');
}

export default function Home() {
  return (
    <Container>
      <SEO title="รับทำเว็บไซต์ ดูแลการตลาดออนไลน์" />
      <Header />
      <div id="hero"></div>
        <Hero />
         <Brand />
         <div id="portfolio"></div>
          <Portfolio />
          <div id="price"></div>
           <Price />
           <div id="about"></div>
            <Why />
             <About />
             <div id="contact"></div>
              <Callaction />
      <Footer />
    </Container>
  );
}
