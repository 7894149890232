import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import whyStyles from "./why.module.css"

export default function Why() {
    return (
        <Container fluid className={whyStyles.whycontainer}>
        <Container fluid="xl">
            <Row className={whyStyles.rowpadding}>
                <Col md={3}></Col>
                <Col md={6}>
                <h3>ทำไมต้องทำเว็บไซต์กับเรา</h3>
                <ul>
                    <li className={whyStyles.li}><span className={whyStyles.corrent}>✓</span>&nbsp;&nbsp; ได้เว็บไซต์ที่เร็วกว่า ทำเว็บไซต์สมัยใหม่ด้วย Jamstack</li>
                    <li className={whyStyles.li}><span className={whyStyles.corrent}>✓</span>&nbsp;&nbsp; ปลอดภัยกว่าเว็บล่มยาก ด้วยเทคโนโลยี Microservice</li>
                    <li className={whyStyles.li}><span className={whyStyles.corrent}>✓</span>&nbsp;&nbsp; ติดอันดับบน Google ง่ายขึ้น (SEO)</li>
                    <li className={whyStyles.li}><span className={whyStyles.corrent}>✓</span>&nbsp;&nbsp; Progressive Web App (PWA) สร้าง Shortcut บนมือถือทำงานได้เสมือน Application</li>
                    <li className={whyStyles.li}><span className={whyStyles.corrent}>✓</span>&nbsp;&nbsp; ได้ไฟล์เว็บไซต์ผ่าน Github ซึ่งนำไปพัฒนาเพื่มเติมกับ Developer คนอื่นได้</li>
                </ul>
                </Col>
                <Col md={3}></Col>
            </Row>
        </Container>
        </Container>
    );
}