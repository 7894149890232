import React from "react"
import { Container, Row, Col, Tabs, Tab} from "react-bootstrap"
import portfolioStyles from "./portfolio.module.css"
import portstamp from "../images/portfolio-stamp.jpg"
import portaa from "../images/portfolio-ads-aa.jpg"
import portklass from "../images/portfolio-klass.jpg"
import portfoliorossaniyomjeans from "../images/portfolio-rossaniyomjeans.jpg"
import portfolioAifirst from "../images/portfolio-aifirst.jpg"
import portfolioPhoenix from "../images/portfolio-phoenix.jpg"
import portfolioSawangsup from "../images/portfolio-sawangsup.jpg"
import portfolioSentoshi from "../images/portfolio-sentoshi.jpg"
import portfolioSongthai from "../images/portfolio-songthai.jpg"
import portfolioWorldwireradio from "../images/portfolio-worldwireradio.jpg"


export default function Portfolio() {
    return (
        <Container fluid className={portfolioStyles.portfolio_container}>
            <Row>
                <Col>
                <Container fluid="xl">
                    <Row>
                        <Col className={portfolioStyles.portfolioheader}>
                        <h3 className={portfolioStyles.h3header}>บริการออกแบบรับทำเว็บไซต์</h3>
                        <p className={portfolioStyles.pheader}>รับทำเว็บไซต์ออกแบบเว็บไซต์แบบมืออาชีพ เว็บสวย เสร็จจริง ไม่ทิ้งงาน</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Tabs defaultActiveKey="All" id="uncontrolled-tab-example">
                            <Tab eventKey="All" title="All">
                                <Container fluid>
                                    <Row>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                            <img src={portfolioAifirst} width="100%" alt="aifirst" />
                                            <h4 className={portfolioStyles.headerh4}>aifirst.co.th</h4>
                                            <p>Corporate Website</p>
                                        </Col>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                            <img src={portfolioPhoenix}  alt="phoenixcocktail" width="100%"/>
                                            <h4 className={portfolioStyles.headerh4}>phoenixcocktail.com</h4>
                                            <p>Catalog Website</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                            <img src={portfolioSawangsup} width="100%" alt="sawangsupconstruction" />
                                            <h4 className={portfolioStyles.headerh4}>sawangsupconstruction.com</h4>
                                            <p>Corporate Website</p>
                                        </Col>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                        <img src={portfolioSentoshi} width="100%" alt="sentoshi" />
                                            <h4 className={portfolioStyles.headerh4}>sentoshi.co</h4>
                                            <p>Catalog Website</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                            <img src={portfolioSongthai} width="100%" alt="songthaitextile" />
                                            <h4 className={portfolioStyles.headerh4}>songthaitextile.com</h4>
                                            <p>Catalog Website</p>
                                        </Col>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                        <img src={portfolioWorldwireradio} width="100%" alt="worldwireradio" />
                                            <h4 className={portfolioStyles.headerh4}>worldwireradio.com</h4>
                                            <p>Catalog Website</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab eventKey="Corporate" title="Corporate">
                                <Container fluid>
                                    <Row>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                            <img src={portfolioAifirst} width="100%" alt="aifirst" />
                                            <h4 className={portfolioStyles.headerh4}>aifirst.co.th</h4>
                                            <p>Corporate Website</p>
                                        </Col>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                            <img src={portfolioSawangsup} width="100%" alt="sawangsupconstruction" />
                                            <h4 className={portfolioStyles.headerh4}>sawangsupconstruction.com</h4>
                                            <p>Corporate Website</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab eventKey="Catalog" title="Catalog">
                                <Container fluid>
                                    <Row>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                            <img src={portfolioPhoenix}  alt="phoenixcocktail" width="100%"/>
                                            <h4 className={portfolioStyles.headerh4}>phoenixcocktail.com</h4>
                                            <p>Catalog Website</p>
                                        </Col>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                        <img src={portfolioSentoshi} width="100%" alt="sentoshi" />
                                            <h4 className={portfolioStyles.headerh4}>sentoshi.co</h4>
                                            <p>Catalog Website</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                            <img src={portfolioSongthai} width="100%" alt="songthaitextile" />
                                            <h4 className={portfolioStyles.headerh4}>songthaitextile.com</h4>
                                            <p>Catalog Website</p>
                                        </Col>
                                        <Col className={portfolioStyles.portfolioitem} md={6}>
                                        <img src={portfolioWorldwireradio} width="100%" alt="worldwireradio" />
                                            <h4 className={portfolioStyles.headerh4}>worldwireradio.com</h4>
                                            <p>Catalog Website</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                        </Tabs>
                        </Col>
                    </Row>
                </Container>
                 </Col>
            </Row>
        </Container>
    );
}