import React from "react"
import { Container, Row , Col, Button } from "react-bootstrap"
import heroStyles from "./hero.module.css"
import Think from "../images/think-01.png"


export default function Hero() {
    return (
        <Container fluid="xl">
            <Row className={heroStyles.hero}>
                <Col md={4}>
                    <h1 className={heroStyles.header}>รับทำเว็บไซต์ ดูแลการตลาดออนไลน์</h1>
                    <p>รับทำเว็บไซต์ สำหรับนักการตลาดที่ต้องการความสามารถของเว็บไซต์ขั้นสูง <b>ความเร็วของการเข้าถึงเว็บไซต์ Pagespeed</b> ให้ได้ผลลัพท์ด้าน SEO หรือ Quality Score ที่ดี
                    ต่อยอดในการสร้างการเติบโตให้กับองค์กรหรือกิจการของท่าน</p>
                    <Button rel="noreferrer" href="https://lin.ee/O1vC4Bk" target="_blank" variant="primary" size="lg">สอบถามเพิ่มเติม</Button>{' '}
                </Col>
                <Col md={1}>
                </Col>
                <Col md={7} className={heroStyles.heroright}>
                <img className={heroStyles.bounce} src={Think} alt="onigitop" />
                </Col>
           </Row>
        </Container>
    );
}