import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import callactionStyles from "./callaction.module.css"

export default function Callaction() {
    return (
        <Container fluid className={callactionStyles.c_container}>
        <Container fluid="xl">
            <Row>
                <Col md={6}>
                <h3 className={callactionStyles.txt_white}>รับทำเว็บไซต์ด้วย JamStack เปลี่ยนเพื่อสิ่งที่ดีกว่า</h3>
                </Col>
                <Col md={6}><center><Button rel="noreferrer" href="https://lin.ee/O1vC4Bk" target="_blank" variant="primary" size="lg">สนใจทำเว็บไซต์ คลิก</Button>{' '}</center></Col>
            </Row>
        </Container>
        </Container>
    );
}