import React from "react"
import { Container, Row, Col, Table, Button } from "react-bootstrap"
import priceStyles from "./price.module.css"

export default function Price() {
    return (
        <Container fluid="xl" className={priceStyles.pricecontainer}>
            <Row>
                <Col>
                <h3 className={priceStyles.header}><span className={priceStyles.txtprimary}>แพ็กเกจราคา</span> ออกแบบรับทำเว็บไซต์</h3>
                <p className={priceStyles.pheader}>ระบบเว็บไซต์ทุกประเภทธุรกิจ เราติดตั้งระบบความปลอดภัย HTTPS ให้ทุกเว็บไซต์ที่เป็นลูกค้าของเรา</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} className={priceStyles.colprice}>
                    <h4>One Page<span className={priceStyles.txtprimary}> Website (Sale Page)</span></h4>
                    <p>ราคา</p>
                    <h3 className={priceStyles.h3price}>15,900</h3>
                    <Table hover size="sm">
                        <tbody>
                            <tr>
                                <td>หน้าเว็บไซต์</td>
                                <td>1 หน้า</td>
                                </tr>
                            <tr>
                                <td>รูปภาพลิขสิทธิ์</td>
                                <td>2 รูป</td>
                            </tr>
                            <tr>
                                <td>พื้นที่</td>
                                <td>ไม่จำกัดผ่าน Github <svg height="28" viewBox="0 0 16 16" version="1.1" width="32" aria-hidden="true"><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg></td>
                            </tr>
                            <tr>
                                <td>Serverless</td>
                                <td>ไม่จำกัดผ่าน Netlify</td>
                            </tr>
                            <tr>
                                <td>Responsive</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                            <tr>
                                <td>บริการหลักการขาย</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                        </tbody>
                    </Table>
                    <p><span className={priceStyles.txtprimary}>รายปี</span> ค่าจด Domain ฟรี 1 ปีแรก</p>
                    <Button rel="noreferrer" href="https://lin.ee/O1vC4Bk" target="_blank" variant="primary" size="lg">สั่งซื้อบริการนี้</Button>{' '}
                </Col>
                <Col md={4} className={priceStyles.colprice}>
                    <h4>Corporate<span className={priceStyles.txtprimary}> Website</span></h4>
                    <p>ราคา</p>
                    <h3 className={priceStyles.h3price}>35,000</h3>
                    <Table hover size="sm">
                        <tbody>
                            <tr>
                                <td>หน้าเว็บไซต์</td>
                                <td>10 หน้า</td>
                                </tr>
                            <tr>
                                <td>รูปภาพลิขสิทธิ์</td>
                                <td>10 รูป</td>
                            </tr>
                            <tr>
                                <td>พื้นที่</td>
                                <td>ไม่จำกัดผ่าน Github <svg height="28" viewBox="0 0 16 16" version="1.1" width="32" aria-hidden="true"><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg></td>
                            </tr>
                            <tr>
                                <td>Serverless</td>
                                <td>ไม่จำกัดผ่าน Netlify</td>
                            </tr>
                            <tr>
                                <td>Responsive</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                            <tr>
                                <td>บริการหลักการขาย</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                        </tbody>
                    </Table>
                    <p><span className={priceStyles.txtprimary}>รายปี</span> ค่าจด Domain ฟรี 1 ปีแรก</p>
                    <Button rel="noreferrer" href="https://lin.ee/O1vC4Bk" target="_blank" variant="primary" size="lg">สั่งซื้อบริการนี้</Button>{' '}
                </Col>
                <Col md={4} className={priceStyles.colprice}>
                    <h4>E-Commerce<span className={priceStyles.txtprimary}> Website</span></h4>
                    <p>ราคา</p>
                    <h3 className={priceStyles.h3price}>65,000</h3>
                    <Table hover size="sm">
                        <tbody>
                            <tr>
                                <td>หน้าเว็บไซต์</td>
                                <td>15-20 หน้ารวมหน้าสินค้า</td>
                                </tr>
                            <tr>
                                <td>รูปภาพลิขสิทธิ์</td>
                                <td>20 รูป</td>
                            </tr>
                            <tr>
                                <td>พื้นที่</td>
                                <td>ไม่จำกัดผ่าน Github <svg height="28" viewBox="0 0 16 16" version="1.1" width="32" aria-hidden="true"><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg></td>
                            </tr>
                            <tr>
                                <td>Serverless</td>
                                <td>ไม่จำกัดผ่าน Netlify</td>
                            </tr>
                            <tr>
                                <td>Responsive</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                            <tr>
                                <td>SEO</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                            <tr>
                                <td>บริการหลักการขาย</td>
                                <td><span className={priceStyles.corrent}>✓</span></td>
                            </tr>
                        </tbody>
                    </Table>
                    <p><span className={priceStyles.txtprimary}>รายปี</span> ค่าจด Domain ฟรี 1 ปีแรก</p>
                    <Button rel="noreferrer" href="https://lin.ee/O1vC4Bk" target="_blank" variant="primary" size="lg">สั่งซื้อบริการนี้</Button>{' '}
                </Col>
            </Row>
        </Container>
    );
}